.main-div-gridDisease{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0 70px;
}
.div-gridDisease{
    width: 95%;
    max-width: 800px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px 45px;
}

@media screen and (max-width:1000px) {
   
    .div-gridDisease{
        grid-template-columns: repeat(3,1fr);
    }
    
}

@media screen and (max-width:500px) {
    .main-div-gridDisease{
        margin: 30px 0 60px;
    }
    .div-gridDisease{
        grid-template-columns: repeat(2,1fr);
        gap: 30px 30px;
}
}