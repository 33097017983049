@import url(main.css);
.return-backlink{
    margin: 50px 0;
    display: flex;
    align-items: center;
    width: fit-content;
}
.text-backlink{
    margin-left: 5px;
    font-size: 2rem;
}
.img-backlink{
    width: 25px;
}