@import url(./main.css);
.mainSec-footer{
    background-color: var(--main-blue-color);
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    color: white;
}
.submainSec-footer{
    display: flex;
    justify-content: space-evenly;
    
}
.menuImgSec-footer{
    display: none;
}
.img-footer{
    width: 200px;
}
.aboutText-footer{
    max-width: 250px;
    font-size: 1.5rem;
}
.sec-footer{
    display: flex;
    flex-direction: column;
}
.linkSec-footer{
    display: flex;
    flex-direction: column;
    transition: .5s;
    
}
.link-footer{
    margin: 8px 0;
    color: rgb(255, 172, 172);
}
.link-footer:hover{
    color: white;
}
.link-footer:hover{
    border-bottom: 2px solid white;
}
.titleSec-footer{
    font-size: 2rem;
}
.linkSec-footer{
    font-size: 1.5rem;
}
.rights-footer{
    font-size: 1.5rem;
    max-width: 500px;
    align-self: center;
    margin-top: 40px;
    text-align: center;
}


@media screen and (max-width:700px) {
    .submainSec-footer{
        flex-direction: column;
        margin-left: 15px;
    }
    
    .menuImgSec-footer{
        display: block;   
        margin-right: 10px;  
        transition: .3s transform;
        transform: rotate(45deg);
        cursor: pointer;
    }
    .menuImgSec-footer.rotate{
        transform: rotate(90deg);
    }
    .linkSec-footer.collapseLinks{
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }
    .linkSec-footer{
        margin-bottom: 20px;
    }
    .menuImg-footer{
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 100px;
    }
    .headerSec-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .img-footer{
        width: 200px;
    }
    .aboutText-footer{
        max-width: 300px;
    }
    .link-footer{
        width: fit-content;
    }
    .sec-footer:nth-child(1){
        order: 1;
        align-items: center;
        text-align: center;
        margin-top: 20px;
    }
}

