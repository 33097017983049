@import url('./main.css');
.btn{
    border-radius: 20px;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 1.3rem;
}
.white{
    background-color: white;
    border: 1px solid var(--main-blue-color);
    color: var(--main-blue-color);
}
.white:hover{
    background-color: var(--main-blue-color);
    color: white;
}
.blue{
    background-color: var(--main-blue-color);
    border: 0;
    color: white;
}
.blue:hover{
    border: 1px solid var(--main-blue-color);
    background-color:white;
    color: var(--main-blue-color);
}