.main-testimonials{
    margin: 40px 0 70px ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.titleSec-testimonials{
    font-size: 3rem;
    color: var(--main-blue-color);
    text-align: center;
}
.title-testimonial{
    margin-bottom: 20px;
    color: var(--secondary-blue-color);
}
.infoSec-testimonials{
    display: flex;
}
.info-testimonials{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0px 10px;
    max-width: 270px;
}
.infotitle-testimonials{
    margin-bottom: 10px;
    font-size: 2rem;
    color: var(--main-blue-color);
}
.infopara-testimonials{
    margin-bottom: 10px;
    font-size: 1.7rem;
}
.infoclient-testimonials{
    margin-top: 0;
    font-size: 1.7rem;
    font-weight: 700;
}

@media screen  and (max-width:680px) {
    .infoSec-testimonials{
        flex-direction: column;
        align-items: center;
        margin: auto 20px;
    }
    .info-testimonials{
        max-width: max-content;
        margin-bottom: 10px;
    }
    .infopara-testimonials{
        margin-top: 5px;
    }
}
