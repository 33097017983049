.mainSec-contact{
    display: flex;
    justify-content: center;
    font-size: 2rem;
    margin: 50px 0;
    color: #232b2b;
}
.form-contact{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    font-weight: 700;
}
.span-form{
    font-size: 1.4rem;
    opacity: .6;
    
}
.input-form{
    margin-bottom: 30px;
    height: 40px;
    border: 2px solid black;
}
.inputarea-form{
    border: 2px solid black;
    margin-bottom: 30px;
}

.sec-contact{
    margin: 0 20px;
    width: 100%;
    max-width: 380px;
    text-align: center;
}
.title-contact{
    font-size: 4.5rem;
    margin-bottom: 40px;
    color: var(--secondary-blue-color);
}
.subtitle-contact{
    font-size: 2rem;
    
    color: var(--main-blue-color);
}
.subSec-contact{
    font-size: 1.8rem;
    margin-bottom: 40px;
}
.label-form{
    text-align: start;
    margin-bottom: 10px;
    color: var(--main-blue-color);
}
.subSec-contact  p{
    margin: 8px 0;
}
.successpara-form{
    font-weight: 300;
    color: red;
}
.btn-form{
    height: 50px;
    border: 2px solid black;
    background-color: var(--main-blue-color);
    font-family: inherit;
    font-size: 2rem;
    color: white;

}

@media screen and (max-width:700px){
    .mainSec-contact{
        flex-direction: column;
        align-items: center;
        margin: 30px 0 60px;
    }
    .title-contact{
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .sec-contact{
        max-width: none;
        margin-bottom: 15px;
    }
    .subtitle-contact{
        font-size: 2.5rem;
        margin-bottom: 15px;
    }
    .subSec-contact{
        margin: 0 40px  ;
    }
    .form-contact{
        margin: 0 50px 10px;
    }
}