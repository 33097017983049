@import url(./main.css);

.main-about{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-about{
    font-size: 6rem;
    letter-spacing: 1px;
    color: white;
}
.titleSec-about{
    display: flex;
    justify-content: center;
    background-color: var(--main-blue-color);
    width: 100%;
    padding: 30px 0;
    text-align: center;
}
.para-about{
    margin: 50px 20px 0;
    max-width: 600px;
    line-height: 1.5;
    font-size: 2rem;
    color: black;
    text-align: center;
}