@import url(./main.css);


.main-initialPage{
    display: flex;
}
.imgSec-initialPage{
    background-image: url(../../public/images/initialpage/consulta.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 50%;
}
.titleSec-initialPage{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    background-color: var(--main-blue-color);
    color: white;
    
}
.title-initialPage{
    background-color: var(--main-blue-color);
    color: white;
    font-weight: 200;
    font-size: 8rem;
    font-style: normal;
    text-align: end;
    margin-right: 30px;
    margin-bottom: 25px;
}

.para-initialPage{
    font-size: 2.5rem;
    font-weight: 100;
    margin-bottom: 15px;
    text-align: end;
    margin-right: 30px;
}
.infoSec-initialPage{
    display: flex;
    font-size: 1.7rem;
    max-width: 600px;
    font-weight: 100;
    justify-content: flex-end;
    line-height: 1.5;
    padding-top: 240px;
    padding-bottom: 50px;
    align-self: flex-end;
}
.address-initialPage{
    margin-right: 50px;
    max-width: 350px;
}
.contact-initialPage {
    margin-right: 30px;
}
        
.contact-initialPage a{
    display: block;
}

@media screen and (max-width:1000px){
    .title-initialPage{
        font-size: 5.6rem;
    }
    .para-initialPage{
        font-size: 2.2rem;
    }
    .infoSec-initialPage{
        align-self: flex-end;
        text-align: end;
        margin-right: 30px;
        font-size: 1.7rem;
    }
    .address-initialPage{
        max-width: 300px;
        margin-right: 30px;
    } 
    .contact-initialPage{
        margin-right: 0;
    }
}
@media screen and (max-width:700px){
    .title-initialPage{
        font-size: 5rem;
    }
    
    .infoSec-initialPage{
        flex-direction: column;
        padding-bottom: 40px;
        align-items: flex-end;
    }
    .address-initialPage{
        margin-bottom: 40px;
        margin-right: 0px;
        width: fit-content;
    }
    .contact-initialPage{
        margin-right: 0;
        width: fit-content;
    }
    
}
@media screen and (max-width:600px){
    .main-initialPage{
        flex-direction: column;
    }
    .title-initialPage{
        margin-right: 15px;
    }
    .para-initialPage{
        margin-right: 15px;
    }
    .infoSec-initialPage{
        padding-bottom: 0;
        margin-right: 15px;
    }
    .imgSec-initialPage{
        width: 100%;
        height: 400px;
    }
   
    .titleSec-initialPage{
        width: 100%;
        padding-left: 0;
        padding-bottom: 50px;
    }
    
    .title-initialPage{
        font-size: 5rem;
        margin-bottom: 0;
        font-weight: bold;
    }
    .para-initialPage{
        margin-bottom: 25px;
    }
    .address-initialPage{
        max-width: 270px;
        
    }
   
    
    
}