@import url(main.css);
li{
    margin: 10px 0;
}
.main-drfrancisco{
    margin: 70px 25px;
    
}
.submain-drfrancisco{
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgSec-drfrancisco{
    margin-right: 40px;
}
.img-drfrancisco{
    border-radius: 100px;
    width: 300px;
}
.infoSec-drfrancisco{
    font-size: 1.5rem;
    line-height: 1.5;
}
.name-drfrancisco{
    color: var(--main-blue-color);
    font-size: 2.5rem;
    margin-bottom: 0;
}
.job-drfrancisco{
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 30px;
}
.details-drfrancisco{
    margin-left: 30px;
    margin-top: 10px;
}
.titleSec-drfrancisco{
    color: var(--main-blue-color);
    margin-bottom: 0;
}

@media screen and (max-width:600px) {
    .submain-drfrancisco{
        flex-direction: column;
    }
    .header-drfrancisco{
        text-align: center;
    }
    .imgSec-drfrancisco{
        margin-right: 0px;
    }
}