@import url(./main.css);

.main-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menuSec-navbar{
    display: none;
}
.header-pagedisease{
    margin-bottom: 50px;
}
.linksSec-navbar{
    display: flex;
    font-size: 1.8rem;
    transition: opacity .5s;
}
.link-navbar{
    margin: 0 15px ;
    text-align: center;
}
.link-navbar.true{
    color: var(--main-blue-color);
}
.link-navbar:hover{
    color: var(--main-blue-color);
    transition: color .3s;
}
.logoImg-navbar{
    width: 250px;
}


@media screen and (min-width:900px){
    .main-navbar .linksSec-navbar{
        max-height: 100vh;
        opacity: 1;
    }
}
@media screen and (max-width:900px) {
    .main-navbar{
        flex-wrap: wrap;
    }
    .menuSec-navbar{
        display: block;
        margin: 0;
        margin-right: 10px;
    }
    .menuIcon-navbar{
        cursor: pointer;
        width: 35px;
    }
    .linksSec-navbar{
        color: white;
        flex-direction: column;
        background-color: var(--main-blue-color);
        min-width: 100%;
        padding: 10px 0;
    }
    .linksSec-navbar.collapsed{
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        padding: 0;
    }
    .link-navbar{
        margin:15px 0 15px 15px;
        text-align: start;
        width: fit-content;
    }
    .link-navbar.true{
        color: white;
        border-bottom: 2px solid white;
    }
    .link-navbar:hover {
        color: white;
        border-bottom: 2px solid white;
    }
}

@media screen and (max-width:500px) {
    .logoImg-navbar{
        width: 200px;
    }
}