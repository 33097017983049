@import url('./main.css');

.div-cardDisease{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.img-cardDisease{
    width: 70px;
}
.title-cardDisease{
    font-size: 1.5rem;
    padding-bottom: 20px;
    padding-top: 5px;
    margin: 0;
}