@import url(main.css);

.h1-title {
    font-size: 5rem;
    margin: 40px 0;
    letter-spacing: 1.5px;
    position: relative;
    width: fit-content;
    align-items: center;
}

.h1-title.forblue::before {
    content: '';
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: -5px;
    left: 0px;
    z-index: 1;
    background-color: var(--main-blue-color);
}
.h1-title.forwhite::before {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    background-color: white;
}

.h1-title.forblue {
    color: var(--main-blue-color);
}

.h1-title.forwhite {
    color: white;
}

