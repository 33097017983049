@import url(./main.css);

.main-maincardDoctor{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-cardDoctor{
    display: flex;
    justify-content: center;
    margin: 40px 0 60px;
}

.cardDoctor-LogoSec{
    font-size: 2rem;
    margin-left: 0px;
    border-left: 3px solid var(--main-blue-color);
    padding-left: 50px;
    display: flex;
    flex-direction: column; 
    align-items: flex-end;
    justify-content: center;
}
.cardDoctor-logopara{
    max-width: 350px;
    text-align: justify;
    color: var(--secondary-blue-color);
}
.cardDoctor-Sec{
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    margin-right: 20px;
    
}
.cardDoctor-imgSec{
    margin-right: 30px;
}
.cardDoctor-img{
    border-radius: 100px;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.75);
    max-width: 300px;
}
.main-cardDoctor .btn{
    width: 80%;
}
.cardDoctor-name{
    color: var(--main-blue-color);
    font-weight: inherit;
}
.cardDoctor-profesion{
    margin: 5px 0;
    font-weight: inherit;
}
.cardDoctor-especialist{
    margin-top: 0;
    font-weight: inherit;
}
.cardDoctor-logoclinica{
    width: 200px;
}
@media screen and (max-width:1000px) {
    .main-cardDoctor{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .cardDoctor-LogoSec{
        border-left: 0;
        padding: 0;
        margin: 0;
        align-items: center;
    }
    .cardDoctor-Sec{
        justify-content:space-between ;
        order: 1;
       
    }
    .cardDoctor-logoclinica{
        width: 300px;
        margin-bottom: 10px;
    }
    .cardDoctor-logopara{
        max-width: 600px;
        margin-bottom: 50px;
        font-weight: 700;
        text-align: center;
    }
    
}
@media screen and (max-width:520px) {
    .main-cardDoctor{
        margin-top: 10px;
    }
    .cardDoctor-logopara{
        margin: 5px 60px 50px;
        max-width: none;
        font-size: 1.5rem;
        font-weight: 700;
        
    }
    .cardDoctor-logoclinica{
        width: 200px;
    }
    .cardDoctor-LogoSec{
        margin-top: 15px;
    }
    .cardDoctor-Sec{
        flex-direction: column;
        font-size: 1.4rem;
        line-height: 1.2;
        margin: 0;
    }
    .cardDoctor-imgSec{
        margin-right: 0;
        margin-bottom: 15px;
    }
    .cardDoctor-infoSec{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}