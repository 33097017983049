.main-notFound{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.title-notFound{
    font-size: 3rem;
}
.subtitle-notFound{
    font-size: 2rem;
}