@import url(./main.css);
.mainSec-location{
    padding: 20px 0 40px;
    display: flex;
    flex-direction: column;
    background-color: var(--main-blue-color);
    align-items: center;
}
.submainSec-location{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 30px 60px;    
}
.infoSec-location{
    margin: 0 40px;
    color: white;
}

.title-location{
    margin-top: 0;
    font-size: 4rem;
    margin-bottom: 15px;
}
.address-location{
    font-weight: 100;
    margin-bottom: 15px;
}

.map-location{
    width: 350px;
    height: 350px;
    border-radius: 20px;
}
.link-location{
    display: flex;
    font-size: 1.7rem;
    align-items: center;
    margin-bottom: 0;
    width: fit-content;
    max-width: 400px;
    min-height: 60px;
}


.linkImg-location{
    height: 35px;
    text-align: center;
    margin-right: 15px;
}


@media screen and (max-width:700px) {
    
    .submainSec-location{
        flex-direction: column;
    }
    .title-location{
        text-align: center;
    }
    .infoSec-location{
        margin-bottom: 70px;
    }
    .mapSec-location{
        align-self: center;
    }
    .link-location{
        font-size: 1.9rem;
    }
}
@media screen and (max-width:500px) {
    .mainSec-location{
        padding: 20px 0 60px;
    }
    .submainSec-location{
        margin: 10px 10px;  
    }
   .infoSec-location{
        margin: 0 15px 80px;
   }
    .title-location{
        font-size: 4.2rem;
        line-height: 1.5;
    }
    
}
