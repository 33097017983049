.links_container{
    position: fixed;
    right: 0;
    top: 50%;
    border-radius: 20px 0 0 20px;
    display: flex;
    flex-direction: column;
    z-index: 2;  
    overflow: hidden;
    width: 60px;
}
.links_container a{
    margin: 0;
    padding: 0;
}
.btn_container{
    width: 100%;
    height:80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.whatsapp_link{
    background-color: #29A71A;
}
.facebook_link{
    background-color: #3B5998;
}
.instagram_link{
    background-color: #ffffff;
}
.instagram_link .sidelink_icon{
    width: 40px;
}
.sidelink_icon{
    width: 50px;
    justify-self: center;
}

@media screen and (max-width:500px){
    .links_container{
        width: 45px;
    }
}