@import url('./main.css');
li{
    margin: 15px 0;    
}
.main-pagedisease{
    margin:50px 20px;   
    line-height: 1.4;
}
.header-pagedisease{
    display: flex;
    color: var(--main-blue-color);
    align-items: center;
}
.headertext-pagedisease{
    margin-left: 25px;
}
.info-pagedisease{
    margin-left: 20px;
}
.img-pagedisease{
    width: 100px;
}
.title-pagedisease{
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 0;
}
.subtitle-pagedisease{
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 2rem;
    color: black;
}
.titleSec-pagedisease{
    color: var(--main-blue-color);
    font-size: 1.8rem;
    margin-bottom: 0;
}
.paraSec-pagedisease{
    margin-top: 0;
    font-size: 1.6rem;
}
.infoSec-pagedisease{
    margin-bottom: 30px;
}
