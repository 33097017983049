:root{
    --main-blue-color:#1a73e8;
    --secondary-gray-color:rgb(200, 200, 200);
    --secondary-blue-color:rgb(13, 13, 135);
    font-size: 62.5%;
}
body{
    font-family: 'SF Pro Display', sans-serif;
    margin:0

}
input{
    font-family: inherit;
    font-size: 1.5rem;
}
a{
    text-decoration: none;
    font-family: inherit;
    color: inherit;
}
h1,h2,h3,h4{
    font-family: inherit;
}